import React from 'react';
import { useEffect, useState } from 'react';
import Input from './components/input';
import Menu from './components/menu'
import './App.css';

function App() {
  


  return (
    <main style={{padding:'20px'}}>
      <Menu />
     

      <Input />

     
    </main>
  );
}

export default App;
